import { Subscription } from 'rxjs';

export class HesSubscription {
  private subscription = new Subscription();

  set add(sub: Subscription) {
    this.subscription.add(sub);
  }

  unsubscribe() {
    this.subscription.unsubscribe();
  }
}
